<template xmlns="http://www.w3.org/1999/html">
  <div
    v-if="
      route && route.params && (route.params.serial || route.params.username)
    "
  >
    <div
      v-if="isEmpty(user) && route.name.includes('Home') && !isLoggedInUser"
      class="center-text full-bottom"
      style="padding-top: 50px"
    >
      <p class="font-20 bold">{{ lang[this.appLang].user_not_found }}</p>
      <br />
      <p class="font-15">
        {{ lang[this.appLang].user_not_found_p }}
      </p>
      <br />
      <router-link
        :to="'/welcome'"
        exact
        style="z-index: 999; margin: 0 !important"
        class="button white-button"
      >
        {{ lang[this.appLang].go_back }}
      </router-link>
    </div>
    <form
      v-else-if="!isEmpty(user) && homeInitialized"
      class="user-section"
      @submit.prevent="save()"
    >
      <div
        style="width: 100%; display: flex; margin-bottom: 20px; padding: 0 12px"
      >
        <a
          v-if="user.photo"
          @click="toggleUploadModal('photo')"
          class="user-image"
        >
          <img
            class="responsive-image user-photo"
            :src="user.photo"
            style="
              border-radius: 50%;
              border: 1px solid #dddddd;
              height: 100%;
              object-fit: cover;
            "
          />
          <img
            v-if="editing"
            class="pull-left photo-upload edit-shadow"
            src="https://cdn.tapni.co/icons/edit.png"
            alt=""
          />
          <p
            v-if="
              false &&
              ((user &&
                ((user && user.is_public) || user.connected || user.isTap)) ||
                (isLoggedIn && isLoggedInUser))
            "
            class="color-black bold small-top-padding"
          >
            {{ lang[this.appLang].taps }}: {{ taps }}
          </p>
        </a>

        <!-- Private profile -->
        <div
          v-if="
            !isEmpty(user) &&
            !editing &&
            !user.connected &&
            user.is_public === false &&
            !user.isTap &&
            !isLoggedInUser &&
            homeInitialized
          "
          class="half-top full-bottom padding-left"
          style="text-align: left"
        >
          <p class="font-20">{{ lang[this.appLang].private_profile }}</p>
          <br />
          <p class="font-13">
            @{{ user.username }}
            <span v-html="lang[this.appLang].private_profile_p"></span>
          </p>
        </div>

        <div
          v-if="
            (isLoggedIn && editing) ||
            user.connected ||
            user.is_public ||
            user.isTap ||
            isLoggedInUser
          "
          class="user-text-section"
        >
          <strong
            class="user-name"
            style="width: 100%; color: #1d1d1d !important; opacity: 1"
          >
            <!-- Name -->
            <textarea
              v-if="editing || (!editing && user.name)"
              id="nameInput"
              maxlength="100"
              v-autogrow
              class="name-input"
              rows="1"
              v-model="user.name"
              :disabled="!isLoggedIn || !editing || !rules.editName"
              :class="{ 'edit-background': editing && rules.editName }"
              :placeholder="lang[this.appLang].name"
            />
          </strong>
          <p
            v-if="user.bio || editing"
            class="user-bio"
            :class="{
              'edit-background tiny-top': editing && rules.editBio,
            }"
          >
            <textarea
              maxlength="110"
              v-model="user.bio"
              :disabled="!isLoggedIn || !editing || !rules.editBio"
              v-autogrow
              style="width: 100% !important; resize: none; color: #1d1d1d"
              :placeholder="lang[this.appLang].profile_bio_p"
            />
          </p>
        </div>
      </div>

      <div class="markersContainer" v-if="user && user.markers && user.markers.length">
        <span v-for="(marker, index) in user.markers" :key="index">{{
          marker.name
        }}</span>
      </div>

      <!-- Action Button -->
      <button
        v-if="
          !isLoggedInUser && (user.is_public || user.isTap) && !user.connected
        "
        @click="connectHandler"
        style="z-index: 999"
        type="button"
        class="button white-button action-button"
      >
        <span v-if="isLoggedIn">{{ lang[this.appLang].connect }}</span>
        <span v-else>{{ lang[this.appLang].exchange_contact }}</span>
      </button>
      <a
        v-if="
          (!isLoggedIn || (isLoggedIn && !isLoggedInUser)) &&
          user.links &&
          user.links.length &&
          false
        "
        target="_blank"
        :href="addToContact"
        style="z-index: 999"
        class="button white-button action-button"
      >
        {{ lang[this.appLang].add_to_contacts }}
      </a>
      <button
        v-else-if="!editing && isLoggedInUser"
        @click.prevent="
          editing = true;
          $router.push({ hash: 'edit' });
        "
        style="z-index: 999"
        type="button"
        @click="editing = false"
        class="button white-button pointer action-button"
      >
        {{ lang[this.appLang].edit_profile }}
      </button>
      <button
        type="submit"
        v-else-if="editing"
        style="z-index: 999"
        class="button white-button pointer action-button"
      >
        {{ lang[this.appLang].profile_preview }}
      </button>
      <button
        v-else-if="user.connected && !isLoggedIn"
        disabled
        style="z-index: 999; user-select: none"
        type="button"
        class="button white-button button-disabled action-button"
      >
        <span>{{ lang[this.appLang].exchanged }}</span>
      </button>
    </form>

    <div
      v-if="!isEmpty(user) && homeInitialized"
      class="user-sub-section margin-bottom-100"
    >
      <!-- Links BEGIN -->
      <div
        v-if="
          (isLoggedIn && editing && !isEmpty(user)) ||
          user.is_public ||
          user.connected ||
          user.isTap ||
          isLoggedInUser
        "
        class="content-boxed small-top bg-white no-top"
        :class="{ 'user-section-edit': editing }"
        :style="
          'text-align: center; margin: 0 auto;' +
          (!editing
            ? ' width: 100%; padding: 0;'
            : 'width: 92%; padding: 0 3%;')
        "
      >
        <h5
          v-if="isLoggedIn && editing"
          class="center-text regularbold small-top small-bottom"
        >
          <span v-if="user.links && user.links.length > 0">{{
            lang[this.appLang].drag_and_drop_links
          }}</span>
          <span v-else>{{ lang[this.appLang].no_links }}</span>
        </h5>
        <div
          v-show="isLoggedIn && editing && user.links.length > 0"
          class="center-text pointer"
        >
          <span
            @click="user.link_style = 'link-grid'"
            :class="{
              'selected-link-icon': user.link_style === 'link-grid',
              'edit-shadow pointer': user.link_style === 'link-list',
            }"
            style="
              display: inline-block;
              width: 10%;
              min-width: 35px;
              border-radius: 15px;
              margin: 0 5px;
              overflow: hidden;
            "
          >
            <img
              src="https://cdn.tapni.co/icons/grid.png"
              class="responsive-image"
            />
          </span>
          <span
            @click="user.link_style = 'link-list'"
            :class="{
              'selected-link-icon': user.link_style === 'link-list',
              'edit-shadow pointer': user.link_style === 'link-grid',
            }"
            style="
              display: inline-block;
              width: 10%;
              min-width: 35px;
              border-radius: 15px;
              margin: 0 5px;
              overflow: hidden;
            "
          >
            <img
              src="https://cdn.tapni.co/icons/list.png"
              class="responsive-image"
            />
          </span>
        </div>
        <draggable
          v-model="sortableLinks"
          :disabled="!isLoggedIn || !editing"
          v-bind="dragOptions"
          filter=".add-button"
          preventOnFilter
        >
          <transition-group>
            <LinkIcon
              v-for="link in user.links"
              :key="link.id"
              :data="link"
              :link-style="user.link_style"
              :disabled="
                user.is_direct &&
                !link.is_direct &&
                editing &&
                isLoggedIn &&
                false
              "
              :editing="
                editing &&
                (Boolean(!link.CompanyId) ||
                  (Boolean(link.CompanyId) && rules.editCompanyLinks))
              "
              class="link-transition"
            />
            <LinkIcon
              v-show="editing"
              :to="'/link/add'"
              :key="'add'"
              :link-style="user.link_style"
              :data="{ type: 'addlink', text: lang[this.appLang].add_link }"
              :editing="false"
              :pulse="user.links.length === 0"
              class="link-transition"
              :class="{ 'add-button': user.link_style === 'link-grid' }"
            />
          </transition-group>
        </draggable>
      </div>
      <!-- Links END -->

      <!-- Message BEGIN -->
      <hr
        v-if="
          (!user.is_active || (user.is_direct && user.links && directLink)) &&
          isLoggedIn &&
          isLoggedInUser
        "
        class="decoration small-bottom no-top"
      />
      <p
        v-if="
          user.is_active &&
          user.is_direct &&
          user.links &&
          directLink &&
          isLoggedIn &&
          isLoggedInUser
        "
        class="center-text regularbold small-bottom font-15"
      >
        {{ lang[this.appLang].direct_link_p1 }}
        <a class="bold pointer" @click="toggleLinkModal(directLink)">
          {{ user.links && directLink ? directLink.text : "" }}
        </a>
      </p>
      <p
        v-if="!user.is_active && isLoggedIn && isLoggedInUser"
        class="center-text regularbold half-top small-bottom font-15"
      >
        <span v-html="lang[this.appLang].profile_not_active"></span>
        <br />
        <br />
        <a
          v-if="user.tags.length > 0"
          @click="toggleProfileModal"
          class="bold pointer"
        >
          {{ lang[this.appLang].activate_profile_now }}
        </a>
      </p>
      <hr
        v-if="!user.is_public && user.is_active && isLoggedIn && isLoggedInUser"
        class="decoration help-bottom"
      />
      <p
        v-if="!user.is_public && user.is_active && isLoggedIn && isLoggedInUser"
        class="
          center-text
          regularbold
          half-top
          help-bottom
          small-bottom
          font-15
        "
      >
        <span v-html="lang[this.appLang].profile_not_public"></span>
        <br />
        <br />
        <a @click="toggleProfileModal" class="bold pointer">
          {{ lang[this.appLang].publish_profile_now }}
        </a>
      </p>
      <!-- Message END -->
    </div>
  </div>
</template>

<script>
import LinkIcon from "../components/LinkIcon";
import { mapState, mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
import Link from "../components/modals/Link";
import UtilService from "../services/UtilService";
import { EventBus } from "../store/event-bus";
export default {
  name: "Home",
  components: {
    Link,
    LinkIcon,
    draggable,
  },
  metaInfo() {
    return this.user && this.user.username
      ? {
          title: "@" + this.user.username,
          titleTemplate: "%s | Tapni",
          meta: [
            {
              vmid: "description",
              name: "description",
              content: this.user.bio,
            },
            {
              vmid: "og:image",
              property: "og:image",
              content: this.user.photo,
            },
          ],
        }
      : {};
  },
  data() {
    return {
      showSettings: false,
      showInfo: false,
      editing: false,
      confirmLogout: false,
      saveTimeout: null,
      homeInitialized: false,
      isRedirected: false
    };
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters(["directLink", "rules"]),
    taps() {
      let count = this.user.visits || 0;
      return `${count} ${this.tapsEmoji(count)}`;
    },
    addToContact() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/v1/users/" +
        this.user.username +
        "/vcard"
      );
    },
    sortableLinks: {
      get() {
        return this.$store.state.user.links;
      },
      set(value) {
        this.$store.commit("updateLinks", value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        handle: ".link-drag",
        delay: 60,
        delayOnTouchOnly: true,
        forceFallback: true,
      };
    },
  },
  methods: {
    ...mapActions([
      "getUser",
      "logout",
      "saveProfile",
      "updateLinkStyle",
      "connect",
      "eventLog",
      "getLinkTypes",
    ]),
    timeoutSave() {
      EventBus.$emit("loadingHeader", true);
      clearInterval(this.saveTimeout);
      this.saveTimeout = setTimeout(() => {
        this.saveProfile({
          name: this.user.name,
          bio: this.user.bio,
          username: this.user.username,
        });
      }, 2000);
    },
    save() {
      this.editing = false;
      this.$forceUpdate();
      this.saveProfile({
        name: this.user.name,
        bio: this.user.bio,
        username: this.user.username,
        preventTopOver: true,
      });
      this.$router.push({ hash: "#preview" });
    },
    async connectHandler() {
      if (!this.isLoggedIn) {
        this.toggleConnectModal();
      } else {
        EventBus.$emit("loadingHeader", true);
        await this.connect({
          UserId: this.user.id,
        });
        await this.getUser({ username: this.user.username });
        this.$forceUpdate();
        setTimeout(() => {
          EventBus.$emit("loadingHeader", false);
        }, 1000);
      }
    },
    toggleConnectModal() {
      EventBus.$emit("toggleConnectModal");
    },
    toggleUploadModal(type = "photo") {
      if (!this.rules.editProfilePicture)
        return EventBus.$emit("toggleForbiddenRuleModal");
      if (this.editing) EventBus.$emit("toggleUploadModal", type);
    },
    togglePasswordModal() {
      if (this.editing) EventBus.$emit("togglePasswordModal");
    },
    toggleProfileModal() {
      EventBus.$emit("toggleProfileModal");
    },
    toggleLinkModal(link) {
      if (link.type === "contactcard")
        EventBus.$emit("toggleContactCardModal", link);
      if (link.type === "googlereview")
        EventBus.$emit("toggleGoogleReviewModal", link);
      else EventBus.$emit("toggleLinkModal", link);
    },
    init(calledBy = "default") {
      if (this.homeInitialized) return;
      EventBus.$emit("closeModal");
      let data = {};
      let serial = this.route.params.serial;
      let username = this.route.params.username;

      // Fix some bug from 11.03.2022
      if (this.$cookies.get("visited-undefined"))
        this.$cookies.remove("visited-undefined");
      if (this.$cookies.get("visited-" + serial) === "undefined")
        this.$cookies.remove("visited-" + serial);
      if (username === "undefined" && this.route.hash === "#edit") {
        this.$store.dispatch("logout", false);
        this.$store.commit("setInitialize", true);
        this.$store.commit("setLoading", false);
        return this.$router.push("/welcome");
      }

      // Format parameters
      if (serial)
        serial = serial.replace(/:/g, "").replace(/ /g, "").toLowerCase();
      if (username) username = username.replace(/ /g, "").toLowerCase();

      if (this.isLoggedIn && username === this.storage.username) {
        data = { username };
      } else if (username) {
        data = { username };
        if (this.$cookies.get("visited-" + username)) {
          data.dontTap = true;
        }
      } else if (serial) {
        data = { serial };
        if (this.$cookies.get("visited-" + serial)) {
          data.dontTap = true;
        }
      } else if (this.$cookies.get("visited-" + username)) {
        data = {
          serial: this.$cookies.get("visited-" + username),
          dontTap: true,
        };
      } else if (!this.isLoggedIn) {
        return this.$router.push("/welcome");
      } else if (this.isLoggedIn && !username) {
        this.$store.commit("setInitialize", true);
        this.$store.commit("setLoading", false);
        this.$router.push(this.home);
      }

      // when dontTap exist as url query param
      if(this.$route.query.dontTap) data.dontTap = true;

      if(!data.dontTap) data.utmParams = UtilService.getUTMParams(this.$route.query)


      /**
       * If the username is not the same for the one that is logged in
       */
      if (
        this.isEmpty(this.user) ||
        (username && username !== this.user.username) ||
        serial ||
        this.route.hash === "#refresh"
      ) {
        if (
          this.route.hash !== "#refresh" ||
          username !== this.storage.username
        ) {
          this.$store.commit("setLoading", true);
        } else {
          this.$store.commit("setInitialize", true);
        }

        this.getUser(data).then(async () => {
          if (this.isEmpty(this.linkTypes)) {
            await this.getLinkTypes();
          }


          if (
            this.user &&
            this.user.is_direct &&
            !this.isLoggedInUser &&
            this.route.hash !== "#preview"
          ) {
            const redirectLink = this.directLink;
            this.eventLog({
              type: "links_direct",
              data: redirectLink,
            });

            setTimeout(() => {
              //if(this.isRedirected) {
                this.homeInitialized = true;
                if (!this.user.connected) {
                  this.$store.commit("setUser", {});
                  this.$router.push("/welcome");
                } else {
                  this.$store.commit("setInitialize", true);
                  this.$store.commit("setLoading", false);
                }
              //}
            }, 2500);
            this.isRedirected = this.redirectLink(redirectLink);

            /*
            if(!this.isRedirected) {
              document.getElementsByClassName('preload-spinner')[0].style.display = "none";
              let pulseLoaderEl = document.getElementsByClassName('pulse-loader')[0];
              pulseLoaderEl.classList.remove("pulse-loader");
              let redirectEl = document.getElementsByClassName('redirectBtn')[0]
              redirectEl.href = redirectLink.url;
              redirectEl.style.display = "";
            }
            */
          }

          if (serial && !this.isEmpty(this.user) && this.user.username) {
            this.$cookies.set("visited-" + this.user.username, serial);
            this.$cookies.set("visited-" + serial, this.user.username);
          }

          if (
            this.isLoggedIn &&
            this.isLoggedInUser &&
            this.user.links.length === 0
          ) {
            if (this.route.hash === "#edit") {
              this.editing = true;
            }
            if (this.route.hash === "#preview") {
              this.editing = false;
            }
          } else {
            this.editing = false;
          }

          if (this.user) {
            this.sortableLinks = this.user.links;
          }
          this.homeInitialized = true;
          this.$store.commit("setInitialize", true);
          if (
            this.user === null ||
            this.isLoggedInUser ||
            this.user.connected ||
            !this.user.is_direct
          ) {
            this.$store.commit("setLoading", false);
          }

          if (this.user && this.user.username) {
            this.$router.push({
              path: "/" + this.user.username,
              hash: "#preview",
            });
          }
        });
      } else {
        if (this.route.hash === "#edit" && this.isLoggedInUser) {
          this.editing = true;
        }

        // If user has any link, open preview view by default
        if (
          this.route.hash !== "#refresh" &&
          this.isLoggedInUser &&
          this.user.links.length > 0
        ) {
          this.editing = false;
        }

        this.homeInitialized = true;
        this.$store.commit("setLoading", false);

        if (!username && !serial) this.$router.push("/welcome");
        else this.$router.push({ hash: "" });
      }
      this.$forceUpdate();
    },
  },
  mounted() {
    if (this.route) {
      this.init();
    }
  },
  watch: {
    editing(nv) {
      EventBus.$emit("HomeEditing", nv);
    },
    "user.link_style"(nv, ov) {
      if (ov !== undefined && !this.loading && this.user) {
        this.updateLinkStyle(nv);
      }
    },
    "user.name"(nv, ov) {
      if (
        ov &&
        nv &&
        !this.loading &&
        this.editing &&
        this.isLoggedInUser &&
        this.homeInitialized
      ) {
        this.timeoutSave();
      }
    },
    "user.bio"(nv, ov) {
      if (
        ov &&
        nv &&
        !this.loading &&
        this.editing &&
        this.isLoggedInUser &&
        this.homeInitialized
      ) {
        this.timeoutSave();
      }
    },
    "route.params.username"(nv) {
      if (nv && this.route.hash !== "#preview") {
        this.homeInitialized = false;
        this.$nextTick(() => this.init("params.username"));
      }
    },
    "route.params.serial"(nv) {
      if (nv) {
        this.homeInitialized = false;
        this.$nextTick(() => this.init("params.serial"));
      }
    },
    "route.hash"(nv) {
      if (nv === "#edit" && this.isLoggedInUser) {
        this.editing = true;
      } else if (nv === "#preview") {
        this.editing = false;
      } else if (nv === "#refresh") {
        this.init("route.hash");
      }
      this.$router.push({ hash: "" });
    },
    "route.name"(nv, ov) {
      if (!ov && nv) this.init("route.name");
    },
  },
};
</script>

<style scoped>
input,
textarea {
  background: transparent;
}
.photo-upload {
  cursor: pointer;
  border-radius: 15px;
  width: auto !important;
  font-size: 10px !important;
  margin-top: -30px !important;
  margin-left: 67px !important;
  height: 30px !important;
  line-height: 16px !important;
}
.cover-upload {
  cursor: pointer;
  position: absolute;
  z-index: 900;
  right: 0;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 20px;
  background-color: rgb(53, 183, 255);
  padding: 2px 10px 0;
}
.profile-icon {
  transition: transform 0.5s;
  display: inline-block;
  margin: 5px;
  max-width: 25%;
}
.profile-icon p {
  padding: 0 10px;
}
.selected-link-icon {
  border: 1px solid rgb(53, 183, 255);
}
.action-button {
  height: 50px;
  width: 95%;
  text-align: center;
  margin: 0 auto;
}
.add-button {
  margin: 0.8% !important;
  padding: 1.8% !important;
}
.link-transition {
  will-change: transform;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
}

.markersContainer {
  overflow-x: scroll;
  white-space: nowrap;
  margin-left: 10px;
  margin-bottom: 20px;
  text-align: left;
}

.markersContainer span {
  color: #fff;
  background-color: #000;
  border-radius: 12px;
  padding: 5px 10px 5px 10px;
  margin-right: 10px;
  font-weight: 500;
}

.name-input {
  font-weight: 600;
  letter-spacing: -0.5px;
  width: 100%!important;
  font-size: 24px;
  resize: none;
  padding: 7px 10px;
  margin-top: -12px;
  color: #1d1d1d!important;
  opacity: 1;
  border-radius: 12px !important;
  margin-bottom: 5px;
}

.user-bio {
  font-size: 14px !important;
  width: 100% !important;
  padding: 7px 10px;
  color: #1d1d1d;
  border-radius: 12px !important;
}

</style>
