var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.route && _vm.route.params && (_vm.route.params.serial || _vm.route.params.username)
  )?_c('div',[(_vm.isEmpty(_vm.user) && _vm.route.name.includes('Home') && !_vm.isLoggedInUser)?_c('div',{staticClass:"center-text full-bottom",staticStyle:{"padding-top":"50px"}},[_c('p',{staticClass:"font-20 bold"},[_vm._v(_vm._s(_vm.lang[this.appLang].user_not_found))]),_c('br'),_c('p',{staticClass:"font-15"},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].user_not_found_p)+" ")]),_c('br'),_c('router-link',{staticClass:"button white-button",staticStyle:{"z-index":"999","margin":"0 !important"},attrs:{"to":'/welcome',"exact":""}},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].go_back)+" ")])],1):(!_vm.isEmpty(_vm.user) && _vm.homeInitialized)?_c('form',{staticClass:"user-section",on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('div',{staticStyle:{"width":"100%","display":"flex","margin-bottom":"20px","padding":"0 12px"}},[(_vm.user.photo)?_c('a',{staticClass:"user-image",on:{"click":function($event){return _vm.toggleUploadModal('photo')}}},[_c('img',{staticClass:"responsive-image user-photo",staticStyle:{"border-radius":"50%","border":"1px solid #dddddd","height":"100%","object-fit":"cover"},attrs:{"src":_vm.user.photo}}),(_vm.editing)?_c('img',{staticClass:"pull-left photo-upload edit-shadow",attrs:{"src":"https://cdn.tapni.co/icons/edit.png","alt":""}}):_vm._e(),(
            false &&
            ((_vm.user &&
              ((_vm.user && _vm.user.is_public) || _vm.user.connected || _vm.user.isTap)) ||
              (_vm.isLoggedIn && _vm.isLoggedInUser))
          )?_c('p',{staticClass:"color-black bold small-top-padding"},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].taps)+": "+_vm._s(_vm.taps)+" ")]):_vm._e()]):_vm._e(),(
          !_vm.isEmpty(_vm.user) &&
          !_vm.editing &&
          !_vm.user.connected &&
          _vm.user.is_public === false &&
          !_vm.user.isTap &&
          !_vm.isLoggedInUser &&
          _vm.homeInitialized
        )?_c('div',{staticClass:"half-top full-bottom padding-left",staticStyle:{"text-align":"left"}},[_c('p',{staticClass:"font-20"},[_vm._v(_vm._s(_vm.lang[this.appLang].private_profile))]),_c('br'),_c('p',{staticClass:"font-13"},[_vm._v(" @"+_vm._s(_vm.user.username)+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.lang[this.appLang].private_profile_p)}})])]):_vm._e(),(
          (_vm.isLoggedIn && _vm.editing) ||
          _vm.user.connected ||
          _vm.user.is_public ||
          _vm.user.isTap ||
          _vm.isLoggedInUser
        )?_c('div',{staticClass:"user-text-section"},[_c('strong',{staticClass:"user-name",staticStyle:{"width":"100%","color":"#1d1d1d !important","opacity":"1"}},[(_vm.editing || (!_vm.editing && _vm.user.name))?_c('textarea',{directives:[{name:"autogrow",rawName:"v-autogrow"},{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],staticClass:"name-input",class:{ 'edit-background': _vm.editing && _vm.rules.editName },attrs:{"id":"nameInput","maxlength":"100","rows":"1","disabled":!_vm.isLoggedIn || !_vm.editing || !_vm.rules.editName,"placeholder":_vm.lang[this.appLang].name},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "name", $event.target.value)}}}):_vm._e()]),(_vm.user.bio || _vm.editing)?_c('p',{staticClass:"user-bio",class:{
            'edit-background tiny-top': _vm.editing && _vm.rules.editBio,
          }},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.bio),expression:"user.bio"},{name:"autogrow",rawName:"v-autogrow"}],staticStyle:{"width":"100% !important","resize":"none","color":"#1d1d1d"},attrs:{"maxlength":"110","disabled":!_vm.isLoggedIn || !_vm.editing || !_vm.rules.editBio,"placeholder":_vm.lang[this.appLang].profile_bio_p},domProps:{"value":(_vm.user.bio)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "bio", $event.target.value)}}})]):_vm._e()]):_vm._e()]),(_vm.user && _vm.user.markers && _vm.user.markers.length)?_c('div',{staticClass:"markersContainer"},_vm._l((_vm.user.markers),function(marker,index){return _c('span',{key:index},[_vm._v(_vm._s(marker.name))])}),0):_vm._e(),(
        !_vm.isLoggedInUser && (_vm.user.is_public || _vm.user.isTap) && !_vm.user.connected
      )?_c('button',{staticClass:"button white-button action-button",staticStyle:{"z-index":"999"},attrs:{"type":"button"},on:{"click":_vm.connectHandler}},[(_vm.isLoggedIn)?_c('span',[_vm._v(_vm._s(_vm.lang[this.appLang].connect))]):_c('span',[_vm._v(_vm._s(_vm.lang[this.appLang].exchange_contact))])]):_vm._e(),(
        (!_vm.isLoggedIn || (_vm.isLoggedIn && !_vm.isLoggedInUser)) &&
        _vm.user.links &&
        _vm.user.links.length &&
        false
      )?_c('a',{staticClass:"button white-button action-button",staticStyle:{"z-index":"999"},attrs:{"target":"_blank","href":_vm.addToContact}},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].add_to_contacts)+" ")]):(!_vm.editing && _vm.isLoggedInUser)?_c('button',{staticClass:"button white-button pointer action-button",staticStyle:{"z-index":"999"},attrs:{"type":"button"},on:{"click":[function($event){$event.preventDefault();_vm.editing = true;
        _vm.$router.push({ hash: 'edit' });},function($event){_vm.editing = false}]}},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].edit_profile)+" ")]):(_vm.editing)?_c('button',{staticClass:"button white-button pointer action-button",staticStyle:{"z-index":"999"},attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].profile_preview)+" ")]):(_vm.user.connected && !_vm.isLoggedIn)?_c('button',{staticClass:"button white-button button-disabled action-button",staticStyle:{"z-index":"999","user-select":"none"},attrs:{"disabled":"","type":"button"}},[_c('span',[_vm._v(_vm._s(_vm.lang[this.appLang].exchanged))])]):_vm._e()]):_vm._e(),(!_vm.isEmpty(_vm.user) && _vm.homeInitialized)?_c('div',{staticClass:"user-sub-section margin-bottom-100"},[(
        (_vm.isLoggedIn && _vm.editing && !_vm.isEmpty(_vm.user)) ||
        _vm.user.is_public ||
        _vm.user.connected ||
        _vm.user.isTap ||
        _vm.isLoggedInUser
      )?_c('div',{staticClass:"content-boxed small-top bg-white no-top",class:{ 'user-section-edit': _vm.editing },style:('text-align: center; margin: 0 auto;' +
        (!_vm.editing
          ? ' width: 100%; padding: 0;'
          : 'width: 92%; padding: 0 3%;'))},[(_vm.isLoggedIn && _vm.editing)?_c('h5',{staticClass:"center-text regularbold small-top small-bottom"},[(_vm.user.links && _vm.user.links.length > 0)?_c('span',[_vm._v(_vm._s(_vm.lang[this.appLang].drag_and_drop_links))]):_c('span',[_vm._v(_vm._s(_vm.lang[this.appLang].no_links))])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn && _vm.editing && _vm.user.links.length > 0),expression:"isLoggedIn && editing && user.links.length > 0"}],staticClass:"center-text pointer"},[_c('span',{class:{
            'selected-link-icon': _vm.user.link_style === 'link-grid',
            'edit-shadow pointer': _vm.user.link_style === 'link-list',
          },staticStyle:{"display":"inline-block","width":"10%","min-width":"35px","border-radius":"15px","margin":"0 5px","overflow":"hidden"},on:{"click":function($event){_vm.user.link_style = 'link-grid'}}},[_c('img',{staticClass:"responsive-image",attrs:{"src":"https://cdn.tapni.co/icons/grid.png"}})]),_c('span',{class:{
            'selected-link-icon': _vm.user.link_style === 'link-list',
            'edit-shadow pointer': _vm.user.link_style === 'link-grid',
          },staticStyle:{"display":"inline-block","width":"10%","min-width":"35px","border-radius":"15px","margin":"0 5px","overflow":"hidden"},on:{"click":function($event){_vm.user.link_style = 'link-list'}}},[_c('img',{staticClass:"responsive-image",attrs:{"src":"https://cdn.tapni.co/icons/list.png"}})])]),_c('draggable',_vm._b({attrs:{"disabled":!_vm.isLoggedIn || !_vm.editing,"filter":".add-button","preventOnFilter":""},model:{value:(_vm.sortableLinks),callback:function ($$v) {_vm.sortableLinks=$$v},expression:"sortableLinks"}},'draggable',_vm.dragOptions,false),[_c('transition-group',[_vm._l((_vm.user.links),function(link){return _c('LinkIcon',{key:link.id,staticClass:"link-transition",attrs:{"data":link,"link-style":_vm.user.link_style,"disabled":_vm.user.is_direct &&
              !link.is_direct &&
              _vm.editing &&
              _vm.isLoggedIn &&
              false,"editing":_vm.editing &&
              (Boolean(!link.CompanyId) ||
                (Boolean(link.CompanyId) && _vm.rules.editCompanyLinks))}})}),_c('LinkIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing),expression:"editing"}],key:'add',staticClass:"link-transition",class:{ 'add-button': _vm.user.link_style === 'link-grid' },attrs:{"to":'/link/add',"link-style":_vm.user.link_style,"data":{ type: 'addlink', text: _vm.lang[this.appLang].add_link },"editing":false,"pulse":_vm.user.links.length === 0}})],2)],1)],1):_vm._e(),(
        (!_vm.user.is_active || (_vm.user.is_direct && _vm.user.links && _vm.directLink)) &&
        _vm.isLoggedIn &&
        _vm.isLoggedInUser
      )?_c('hr',{staticClass:"decoration small-bottom no-top"}):_vm._e(),(
        _vm.user.is_active &&
        _vm.user.is_direct &&
        _vm.user.links &&
        _vm.directLink &&
        _vm.isLoggedIn &&
        _vm.isLoggedInUser
      )?_c('p',{staticClass:"center-text regularbold small-bottom font-15"},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].direct_link_p1)+" "),_c('a',{staticClass:"bold pointer",on:{"click":function($event){return _vm.toggleLinkModal(_vm.directLink)}}},[_vm._v(" "+_vm._s(_vm.user.links && _vm.directLink ? _vm.directLink.text : "")+" ")])]):_vm._e(),(!_vm.user.is_active && _vm.isLoggedIn && _vm.isLoggedInUser)?_c('p',{staticClass:"center-text regularbold half-top small-bottom font-15"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.lang[this.appLang].profile_not_active)}}),_c('br'),_c('br'),(_vm.user.tags.length > 0)?_c('a',{staticClass:"bold pointer",on:{"click":_vm.toggleProfileModal}},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].activate_profile_now)+" ")]):_vm._e()]):_vm._e(),(!_vm.user.is_public && _vm.user.is_active && _vm.isLoggedIn && _vm.isLoggedInUser)?_c('hr',{staticClass:"decoration help-bottom"}):_vm._e(),(!_vm.user.is_public && _vm.user.is_active && _vm.isLoggedIn && _vm.isLoggedInUser)?_c('p',{staticClass:"center-text regularbold half-top help-bottom small-bottom font-15"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.lang[this.appLang].profile_not_public)}}),_c('br'),_c('br'),_c('a',{staticClass:"bold pointer",on:{"click":_vm.toggleProfileModal}},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].publish_profile_now)+" ")])]):_vm._e()]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }